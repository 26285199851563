import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { API_URI, IS_SERVER } from 'src/constants';
import { useUserCan } from './userHooks';
import { useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import Helper from 'src/front/helpers/Helper';
import globalConfig from 'src/utils/globalConfig';
import GlobalContext from 'src/context/GlobalContext';
import useHandlePopup from 'src/front/helpers/FrontContext';
import $ from 'jquery';

const cacheTranslates = typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.translates ? window.__REACT_INIT_DATA__.translates.translates : {};
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.translates) delete window.__REACT_INIT_DATA__.translates;

const sentStrings = [];
const callbacks = {};

export default (data) => {
  const { context: { isScanningConstants } } = useContext(GlobalContext);
  const { component, componentLanguage, componentLocalization, fronContext } = typeof data == 'object' ? data : { component: data };
  let { lang: currentLanguage, languages, langPrefix, localization } = useSelector((state) => state.language);
  if (componentLanguage && languages.filter((l) => l._id == componentLanguage && l.active && l.ready && !l.deleting).length) currentLanguage = componentLanguage;
  if (componentLocalization !== undefined && (componentLocalization in languages.filter((l) => l._id == currentLanguage)[0].localizations)) localization = componentLocalization;
  else if (!IS_SERVER && !(localization in languages.filter((l) => l._id == currentLanguage)[0].localizations)) localization = 0;
  const currentLanguageRef = useRef(currentLanguage);
  const userCan = useUserCan();
  const [translates, setTranslates] = useState(component && cacheTranslates[component] && cacheTranslates[component][currentLanguage] ? cacheTranslates[component][currentLanguage] : null);
  const [firstRender, setFirstRender] = useState(true);
  const isMounted = useRef(true);
  const [searchConstantBlinking, setSearchConstantBlinking] = useState(true);
  const [scrollToSearchingConstant, setScrollToSearchingConstant] = useState({});
  const searchingConstant = ((!IS_SERVER && window.location.search.match(/(?:\?|&)findConstant=([^&]+)/)) || [])[1];
  const handleOpenPopup = useHandlePopup({ fronContext });

  const handleSetTranslates = (t) => {
    if (component) {
      if (!cacheTranslates[component]) cacheTranslates[component] = {};
      cacheTranslates[component][currentLanguage] = t;
      if (isMounted.current) setTranslates(t);
    }
  };

  const quickEditHandle = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    let el = ev.target;
    while (el.tagName != 'CONSTANT-TRANSLATE-CONTROLS-WRAPPER') el = el.parentNode;
    el = el.constant;
    const string = el.getElementsByTagName('constant-translate-string')[0];
  
    el.menu.style.opacity = 0;
    
    el.hoverTimeout = setTimeout(() => {
      el.appendChild(el.menu);
      el.menu.removeAttribute('style');
      el.hoverTimeout = null;
      clearTimeout(el.menu.checkTimer[1]);
      el.menu.checkTimer = null;
    }, 300);

    el.onclick = function (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    };

    let sel, range;
    string.innerHTML = el.getAttribute('data-translate');
    string.setAttribute('contenteditable', '');
    if (window.getSelection && document.createRange) {
        range = document.createRange();
        range.selectNodeContents(string);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(string);
        range.collapse(true);
        range.select();
    }
  };

  const editHendle = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const tab = window.open(lang.l(ev.target.getAttribute('data-link')), '_blank');
    tab.focus();
  };

  const onBlurHendle = (ev) => {
    let el = ev.target, translate = ev.target.innerHTML.replace(/<.*?>/g, '');
    while (el.tagName != 'CONSTANT-TRANSLATE') el = el.parentNode;

    el.onclick = null;

    if (el.getAttribute('data-translate') != translate) {
      el.setAttribute('data-translate', translate);
  
      const originalID = el.getAttribute('data-original');
      const variant = el.getAttribute('data-variant');

      for (const comp in cacheTranslates) {
        for (const lang in cacheTranslates[comp]) {
          if (lang == currentLanguage) {
            for (const originalText in cacheTranslates[comp][lang]) {
              if (cacheTranslates[comp][lang][originalText].original == originalID) {
                cacheTranslates[comp][lang][originalText].translate[variant] = translate;
              }
            }
          }
        }
      }

      for (const originalText in translates) {
        if (translates[originalText].original == originalID) {
          setTranslates({ ...translates, [originalText]: { ...translates[originalText], translate: translates[originalText].translate.map((t, i) => i == variant ? translate : t) } });
        }
      }

      axios
        .post(`${API_URI}/lang/quick-edit`, { originalID, translate, variant, language: currentLanguage, localization }, { withCredentials: true });
    }

    try {
      const tokens = JSON.parse(el.getAttribute('data-tokens'));
      for (let t in tokens) {
        translate = translate.replace(new RegExp(t, 'g'), tokens[t]);
      }
    } catch (ex) {}

    ev.target.innerHTML = translate;
    ev.target.removeAttribute('contenteditable');
  };

  const onMouseEnterHendle = (ev) => {
    let el = ev.target;
    while (!['CONSTANT-TRANSLATE', 'CONSTANT-TRANSLATE-CONTROLS-WRAPPER'].includes(el.tagName)) el = el.parentNode;
    if (el.tagName == 'CONSTANT-TRANSLATE-CONTROLS-WRAPPER') el = el.constant;

    if (el.getElementsByTagName('constant-translate-string')[0].getAttribute('contenteditable') === null) {
      if (el.hoverTimeout) {
        clearTimeout(el.hoverTimeout);
        el.hoverTimeout = null;
      } else {
        el.menu = el.getElementsByTagName('constant-translate-controls-wrapper')[0];
        el.menu.constant = el;
        el.menu.style.display = 'block';
        document.body.appendChild(el.menu);

        el.menu.checkTimer = [
          () => el.menu.checkTimer[1] = setTimeout(() => {
            if (el.menu.constant.offsetParent) el.menu.checkTimer[0]();
            else {
              el.menu.style.opacity = 0;

              setTimeout(() => el.menu.parentNode.removeChild(el.menu), 300);
            }
          }, 100)
        ];
        el.menu.checkTimer[0]();
      }
    
      const language = languages.filter((l) => l._id == currentLanguage)[0];
      const bounding = el.getBoundingClientRect();
      const left = bounding.left + (window.scrollX || document.body.scrollLeft || document.documentElement.scrollLeft);
      const controlsWidth = el.menu.offsetWidth;
    
      el.menu.style.top = `${bounding.bottom + (window.scrollY || document.body.scrollTop || document.documentElement.scrollTop)}px`;
      el.menu.style.opacity = 1;
    
      if (language && language.direction == 'rtl') {
        if (left + el.offsetWidth >= controlsWidth) {
          el.menu.style.left = `${left + el.offsetWidth - controlsWidth}px`;
        } else {
          el.menu.style.left = `${left}px`;
        }
      } else {
        if (left + controlsWidth <= window.innerWidth) {
          el.menu.style.left = `${left}px`;
        } else {
          el.menu.style.left = `${left - controlsWidth + el.offsetWidth}px`;
        }
      }
    }
  };

  const onMouseLeaveHendle = (ev) => {
    let el = ev.target;
    while (!['CONSTANT-TRANSLATE', 'CONSTANT-TRANSLATE-CONTROLS-WRAPPER'].includes(el.tagName)) el = el.parentNode;
    if (el.tagName == 'CONSTANT-TRANSLATE-CONTROLS-WRAPPER') el = el.constant;
    
    if (el.menu && el.getElementsByTagName('constant-translate-string')[0].getAttribute('contenteditable') === null) {
      el.menu.style.opacity = 0;
    
      el.hoverTimeout = setTimeout(() => {
        el.appendChild(el.menu);
        el.menu.removeAttribute('style');
        el.hoverTimeout = null;
        if (el.menu.checkTimer) {
          clearTimeout(el.menu.checkTimer[1]);
          el.menu.checkTimer = null;
        }
      }, 300);
    }
  };

  const lang = {
    component,
    showControls: true,
    loaded: translates && currentLanguage == currentLanguageRef.current ? true : false,
    translates: translates,
    currentLanguage: languages.filter((l) => l._id == currentLanguage)[0],
    currentLocalizationIndex: localization,
    currentLocalization: !IS_SERVER && languages.filter((l) => l._id == currentLanguage)[0].localizations[localization],
    render(string, params) {
      return Helper.toReact(Helper.parseHtmlString(string), params);
    },
    t(input, tokens, params = {}) {
      if (!input || !component) return '';

      if (typeof params == 'boolean') params = { hideControls: params };
      else if (params?.constructor  !== Object) params = {};

      const { string, variant } = typeof input == 'string' ? { string: input, variant: 0 } : input;
      const withHTML = /<\/?.*?>/.test(string);
      if (!string) return '';

      if (IS_SERVER) {
        const specChars = (s) => s.replace(/\{|\}/g, (v) => ({ '{': '&lf;', '}': '&rf;' })[v]);
        let _tokens = '';

        if (tokens) {
          for (const t in tokens) {
            _tokens += `'${t}': `;

            if (typeof tokens[t] == 'string') _tokens += /^\{print .*?\}/.test(tokens[t]) ? tokens[t].replace(/^\{print |\}/g, '') : `'${tokens[t].replace(/(')/g, '\\$1')}'`;
            else _tokens += tokens[t];

            _tokens += `,`;
          }
        }

        const wrapper = ['', ''];

        return isScanningConstants ?
        `{print '|constantID:' + translates.translates['${component}'][${specChars(/^\{print .+?\}$/.test(string) ? string.replace(/^\{print |\}$/g, '') : "'" + string.replace(/(')/g, '\\$1') + "'")}].original + '|'}` :
        `{print translate(${specChars(/^\{print .+?\}$/.test(string) ? string.replace(/^\{print |\}$/g, '') : "'" + string.replace(/(')/g, '\\$1') + "'")}, &lf;${_tokens}&rf;, '${component}', ${variant}, ${Helper.objectToString(params).replace(/\{/g, '&lf;').replace(/\}/g, '&rf;')})}`;
      } else if (typeof this.translates == 'object' && this.translates !== null && string in this.translates) {
        let translatedOriginString = variant in this.translates[string].translate ? this.translates[string].translate[variant] : this.translates[string].translate[0];
        translatedOriginString = Helper.getLocalizationText(translatedOriginString, this.currentLocalization.shortName);
        let translatedString = translatedOriginString;
        if (tokens) {
          for (let t in tokens) {
            translatedString = translatedString.replace(new RegExp(t, 'g'), tokens[t]);
          }
        }
        if (isScanningConstants) return `|constantID:${this.translates[string].original}|`;
        else if (userCan('editTranslates') && !firstRender && this.showControls && !params.hideControls && typeof window == 'object' && window != null) {
          this.showControls = false;
          const inSearch = searchingConstant === this.translates[string].original;
          const onMouseEnter = () => setSearchConstantBlinking(false);
          const edit = this.t('Edit');
          const quickEdit = this.t('Quick edit');
          this.showControls = true;

          return (
            <constant-translate onMouseEnter={onMouseEnterHendle} onMouseLeave={onMouseLeaveHendle} data-original={this.translates[string].original} data-translate={translatedOriginString} data-variant={variant} data-tokens={tokens ? JSON.stringify(tokens) : ''}>
              <constant-translate-string { ...inSearch && searchConstantBlinking && { class: 'constant-in-search', onMouseEnter, ref: (ref) => ref && !scrollToSearchingConstant.current?.parentNode && ((scrollToSearchingConstant.current = ref) && setScrollToSearchingConstant({ current: ref })) } } { ...!withHTML ? { onBlur: onBlurHendle } : {} }>{this.render(translatedString, params)}</constant-translate-string>
              <constant-translate-controls-wrapper onClick={(ev) => ev.preventDefault()}>
                <constant-translate-controls>
                  {!withHTML && <constant-translate-control onClick={quickEditHandle} href="#">{quickEdit}</constant-translate-control>}
                  <constant-translate-control onClick={editHendle} data-link={`/management/languages/constants/${this.translates[string].original}${variant > 0 ? '?variant=' + variant : ''}`}>{edit}</constant-translate-control>
                </constant-translate-controls>
              </constant-translate-controls-wrapper>
            </constant-translate>
          );
        }
        else return params.hideControls ? translatedString : this.render(translatedString, params);
      } else {
        if (this.loaded && userCan('editTranslates') && !sentStrings.includes(string)) {
          sentStrings.push(string);
          axios
            .post(`${API_URI}/lang/set-translates`, { string, component }, { withCredentials: true });
        }
        return params.hideControls ? string : this.render(string, params);
      }
    },
    l(p, c = {}) {
      if (p || p === '') {
        const check = (l) => {
          if (l == '/') return "(!langPrefix ? '/' : '')";
          else if (/^\{print .+?\}$/.test(l)) {
            const v = `(${l.replace(/^\{print |\}$/g, '')})`;
            return `(${v} === '/' ? (!langPrefix ? '/' : '') : ${v})`;
          } else return `'${l.replace(/(')/g, '\\$1')}'`;
        };

        if (typeof p == 'string') return IS_SERVER ? `{print langPrefix + ${check(p)}}` : `${c.absolute ? (this.currentLocalization.domain || globalConfig().defaultClientUri) : ''}${langPrefix}${!langPrefix || p != '/' ? p : ''}`;
        else if (typeof p == 'object' && typeof p.slice == 'function') return p.map((v) => IS_SERVER ? `{print langPrefix + ${check(v)}}` : `${langPrefix}${!langPrefix || v != '/' ? v : ''}`);
      }
      return '';
    },
    ll(link, fromLang, toLang) {
      if (IS_SERVER) {
        return `{print ((dynamicValue = languages.filter((l) => l._id == ${toLang})[0]).domain || config.defaultClientUri) + (!dynamicValue.domain && dynamicValue._id != config.defaultLanguageShortName ? '/' + dynamicValue.shortName : '') + (!(dynamicValue = languages.filter((l) => l._id == lang)[0]).domain && dynamicValue._id != config.defaultLanguageShortName ? requestUrl.substring(dynamicValue.shortName.length + 1) : requestUrl)}`;
      } else {
        const gc = globalConfig();
        const to = languages.filter((l) => l._id == toLang[0])[0].localizations[toLang[1]];
        return `${to.domain || gc.defaultClientUri}${!to.domain && to.shortName != gc.defaultLanguageShortName ? '/' +  to.shortName : ''}${!this.currentLocalization.domain && this.currentLocalization.shortName != gc.defaultLanguageShortName ? link.substring(this.currentLocalization.shortName.length + 1) : (link === '/' ? '' : link)}`;
      }
    },
    isLink(link) {
      if (IS_SERVER) {
        return `${this.l(link).replace(/^\{print |\}$/g, '')} === requestUrl`;
      } else {
        return this.l(link) === window.location.href.replace(/^https?:\/\/[^/]+/, '');
      }
    },
    localizationText(text) {
      if (IS_SERVER) {
        return `{print (dynamicValue = ${/^\{print .+?\}$/.test(text) ? text.replace(/^\{print |\}$/g, '') : "'" + text.replace(/(')/g, '\\$1') + "'"}, Helper.getLocalizationText(typeof dynamicValue == 'string' ? dynamicValue : Helper.getFieldValue(dynamicValue, staticData.language._id), staticData.language.localizations[localization].shortName).replace(new RegExp(Object.keys(staticData.globalTokens).join('|'), 'g'), (t) => staticData.globalTokens[t]))}`;
      } else {
        const tokens = {
          '%%DOMAIN%%': Helper.token('DOMAIN', window.location.hostname),
          '%%PROJECT%%': this.currentLocalization.project,
          '%%BASE_URI%%': langPrefix
        };

        return Helper.getLocalizationText(typeof text == 'string' ? text : Helper.getFieldValue(text, this.currentLanguage._id), this.currentLocalization.shortName).replace(new RegExp(Object.keys(tokens).join('|'), 'g'), (t) => tokens[t]);
      }
    }
  };

  useEffect(() => {
    setFirstRender(false);

    return () => { isMounted.current = false; };
  }, []);

  useEffect(() => {
    if (component && (!translates || currentLanguage != currentLanguageRef.current)) {

      if (component in cacheTranslates && currentLanguage in cacheTranslates[component]) {
        currentLanguageRef.current = currentLanguage;
        setTranslates({ ...cacheTranslates[component][currentLanguage] });
      } else {
        if (component in callbacks && currentLanguage in callbacks[component]) {
          callbacks[component][currentLanguage].push((t) => {
            currentLanguageRef.current = currentLanguage;
            setTranslates(t);
          });
        } else {
          if (!(component in callbacks)) callbacks[component] = {};
          if (!(currentLanguage in callbacks[component])) callbacks[component][currentLanguage] = [];

          axios
            .get(`${API_URI}/lang/get-translates/${component}/${currentLanguage}`)
            .then((response) => {
              const translates = (response?.data?.translates && response?.data?.translates[component]) || {};

              callbacks[component][currentLanguage].forEach((f) => f(translates));

              delete callbacks[component][currentLanguage];
              if (!Object.keys(callbacks[component]).length) delete callbacks[component];

              currentLanguageRef.current = currentLanguage;
              handleSetTranslates(translates);
            })
            .catch((error) => {
              handleSetTranslates({});
            });
        }
      }
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (scrollToSearchingConstant.current) {
      setTimeout(() => {
        const popup = $(scrollToSearchingConstant.current).closest('[data-popup-name]');

        if (popup.length) {
          const popupName = popup.attr('data-popup-name');

          handleOpenPopup({
            element: $(`#${popup.attr('id')} > div`),
            name: popupName,
            closeCheck: (ev) => ev.target.id === popupName,
          });
        } else {
          showBlockLoop:
          for (let showBlock = scrollToSearchingConstant.current; showBlock;) {
            for (let scrollElement = showBlock.parentNode; scrollElement; scrollElement = scrollElement.parentNode) {
              const tagName = scrollElement.tagName.toLowerCase();

              if (tagName === 'body') {
                $('html, body').animate({
                  scrollTop: $(scrollToSearchingConstant.current).offset().top + scrollToSearchingConstant.current.offsetHeight / 2 - window.innerHeight / 2
                }, 1000);
                break showBlockLoop;
              } else if (['scroll', 'auto', 'hidden'].includes(window.getComputedStyle(scrollElement).getPropertyValue('overflow'))) {
                const scrollTop = $(scrollToSearchingConstant.current).offset().top - $(scrollElement).offset().top + scrollElement.scrollTop - scrollElement.clientHeight / 2 + scrollToSearchingConstant.current.offsetHeight / 2;
                $(scrollElement).animate({ scrollTop }, 1000);
                showBlock = scrollElement;
                break;
              }
            }
          }
        }
      });
    }
  }, [scrollToSearchingConstant]);
  
  return lang;
};
